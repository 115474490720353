import {
  BeakerIcon,
  PhotoIcon,
  RectangleGroupIcon,
  RocketLaunchIcon,
  TrophyIcon,
} from "@heroicons/vue/20/solid";
import { AnyCreativeReport } from "~/types/AnyCreativeReport";
import {
  FilesSortOrderComparisonReportEntry,
  FilesSortOrderEntry,
  FilesSortOrderInspirationBoardEntry,
  FilesSortOrderLaunchReportEntry,
  FilesSortOrderReportingReportEntry,
  FilesSortOrderTestingLogReportEntry,
  Folder,
} from "~/types/FolderStructure";
import { InspirationBoard } from "~/types/InspirationBoard";
import { LaunchReport } from "~/types/LaunchReport";
import { TestingLogReport } from "~/types/TestingLog";

export const useSidebar = () => {
  const folderToFolderStructure = (dto: {
    folder: Folder;
    boards: InspirationBoard[];
    reports: AnyCreativeReport[];
    testingLogs: TestingLogReport[];
    launchReports: LaunchReport[];
  }) => {
    const reportFiles = dto.reports
      .filter((r) => r.folderId === dto.folder.id)
      .map<
        | (FilesSortOrderComparisonReportEntry & { uuid: string })
        | (FilesSortOrderReportingReportEntry & { uuid: string })
        | (FilesSortOrderTestingLogReportEntry & { uuid: string })
        | (FilesSortOrderLaunchReportEntry & { uuid: string })
      >((report) => ({
        id: report.id,
        uuid: report.uuid,
        type:
          report.type === "topcreatives"
            ? "creative_reporting_report"
            : "creative_comparison_report",
        fileSortOrder: report.fileSortOrder ?? 0,
      }));
    const boardFiles = dto.boards
      .filter((b) => b.folderId === dto.folder.id)
      .map<FilesSortOrderInspirationBoardEntry & { uuid: string }>((board) => ({
        id: board.id,
        uuid: board.uuid,
        type: "inspiration_board",
        fileSortOrder: board.fileSortOrder ?? 0,
      }));
    const testingLogFiles = dto.testingLogs
      .filter((t) => t.folderId === dto.folder.id)
      .map<FilesSortOrderTestingLogReportEntry & { uuid: string }>(
        (testingLog) => ({
          id: testingLog.id,
          uuid: testingLog.uuid,
          type: "testing_log_report",
          fileSortOrder: testingLog.fileSortOrder ?? 0,
        }),
      );
    const launchReportFiles = dto.launchReports
      .filter((lr) => lr.folderId === dto.folder.id)
      .map<FilesSortOrderLaunchReportEntry & { uuid: string }>(
        (launchReport) => ({
          id: launchReport.id,
          uuid: launchReport.uuid,
          type: "launch_report",
          fileSortOrder: launchReport.fileSortOrder ?? 0,
        }),
      );
    const files: Array<FilesSortOrderEntry & { uuid: string }> = [
      ...reportFiles,
      ...boardFiles,
      ...testingLogFiles,
      ...launchReportFiles,
    ].sort((a, b) => a.fileSortOrder - b.fileSortOrder);
    return {
      folderId: dto.folder.id,
      folderSortOrder: dto.folder.folderSortOrder,
      files,
    };
  };

  // Pass files as arg for reactivity
  const getFolderSubnavigation = (dto: {
    folderId: number | null;
    boards: InspirationBoard[];
    reports: AnyCreativeReport[];
    testingLogs: TestingLogReport[];
    launchReports: LaunchReport[];
  }) => {
    const reportNavigation = dto.reports
      .filter((r) => r.folderId === dto.folderId)
      .map((report) => getReportNavigation(report));
    const boardNavigation = dto.boards
      .filter((b) => b.folderId === dto.folderId)
      .map((board) => getBoardNavigation(board));
    const testingLogNavigation = dto.testingLogs
      .filter((t) => t.folderId === dto.folderId)
      .map((testingLog) => getTestingLogNavigation(testingLog));
    const launchReportNavigation = dto.launchReports
      .filter((lr) => lr.folderId === dto.folderId)
      .map((launchReport) => getLaunchReportNavigation(launchReport));
    return [
      ...reportNavigation,
      ...boardNavigation,
      ...testingLogNavigation,
      ...launchReportNavigation,
    ].sort((a, b) => (a.fileSortOrder ?? 0) - (b.fileSortOrder ?? 0));
  };

  const getBoardNavigation = (board: InspirationBoard) => {
    const { getInspirationBoardDetailPage, getInspirationBoardDuplicatePage } =
      useInspirationBoard();

    return {
      id: board.uuid,
      name: board.title ?? "Untitled",
      href: getInspirationBoardDetailPage(board.uuid),
      icon: "📌",
      current: useIsCurrentRoute(getInspirationBoardDetailPage(board.uuid)),
      duplicateHref: getInspirationBoardDuplicatePage(board.id),
      type: "board",
      fileSortOrder: board.fileSortOrder,
    };
  };

  const getReportNavigation = (report: AnyCreativeReport) => {
    const { getReportDetailPage, getReportKey, getReportDuplicatePage } =
      useAnyCreativeReport();

    return {
      id: getReportKey(report),
      name: report.title ?? "Untitled",
      href: getReportDetailPage(report),
      icon: report.type === "topcreatives" ? "📈" : "📊",
      current: useIsCurrentRoute(getReportDetailPage(report)),
      type: report.type,
      duplicateHref: getReportDuplicatePage(report),
      fileSortOrder: report.fileSortOrder,
    };
  };

  const getTestingLogNavigation = (testingLog: TestingLogReport) => {
    const { getReportDetailPage, getReportDuplicatePage } = useTestingLog();

    return {
      id: testingLog.uuid,
      name: testingLog.title ?? "Untitled",
      href: getReportDetailPage(testingLog.uuid),
      icon: "📋",
      current: useIsCurrentRoute(getReportDetailPage(testingLog.uuid)),
      duplicateHref: getReportDuplicatePage(testingLog.id),
      type: "testingLog",
      fileSortOrder: testingLog.fileSortOrder,
    };
  };

  const getLaunchReportNavigation = (launchReport: LaunchReport) => {
    const { getReportDetailPage, getReportDuplicatePage } = useLaunchReport();

    return {
      id: launchReport.uuid,
      name: launchReport.title ?? "Untitled",
      href: getReportDetailPage(launchReport.uuid),
      icon: "🚀",
      current: useIsCurrentRoute(getReportDetailPage(launchReport.uuid)),
      duplicateHref: getReportDuplicatePage(launchReport.id),
      type: "launchReport",
      fileSortOrder: launchReport.fileSortOrder,
    };
  };

  const getIconComponent = (type: string) => {
    switch (type) {
      case "topcreatives":
        return TrophyIcon;
      case "comparison":
        return RectangleGroupIcon;
      case "launchReport":
        return RocketLaunchIcon;
      case "testingLog":
        return BeakerIcon;
      case "board":
        return PhotoIcon;
    }
  };

  const getIconColor = (type: string) => {
    switch (type) {
      case "topcreatives":
        return "text-yellow-400";
      case "comparison":
        return "text-primary-400";
      case "launchReport":
        return "text-secondary-400";
      case "testingLog":
        return "text-blue-400";
      case "board":
        return "text-red-400";
    }
  };

  const getIconBgColor = (type: string) => {
    switch (type) {
      case "topcreatives":
        return "bg-yellow-50";
      case "comparison":
        return "bg-primary-50";
      case "launchReport":
        return "bg-secondary-50";
      case "testingLog":
        return "bg-blue-50";
      case "board":
        return "bg-red-50";
    }
  };

  const getIconBorderColor = (type: string) => {
    switch (type) {
      case "topcreatives":
        return "border-yellow-400";
      case "comparison":
        return "border-primary-400";
      case "launchReport":
        return "border-secondary-400";
      case "testingLog":
        return "border-blue-400";
      case "board":
        return "border-red-400";
    }
  };

  return {
    getFolderSubnavigation,
    folderToFolderStructure,
    getIconComponent,
    getIconColor,
    getIconBgColor,
    getIconBorderColor,
  };
};
